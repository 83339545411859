import React from 'react';
import OtpInput from 'react-otp-input';
import { Button } from 'antd';
import {
  StContainer,
  StOtpContainer,
  StContent,
  StTitle,
  StVerificationCodeTitle,
  StVerificationCodeContent,
  otpInputStyle,
  otpInputErrorStyle,
  StOtpInputContainer,
  StButtonLink,
  StOtpInputErrorMessage,
} from './styled';

export const RegisterOtp = ({
  otpValue,
  setOtpValue,
  isOtpError,
  setOtpError,
  onSubmit,
  onRequestNewCode,
}) => {
  const otpLength = 6;
  const isOtpCompleted = otpValue.length === otpLength;

  const onChangeOtpInput = (otp) => {
    setOtpValue(otp);
    setOtpError(false);
  };

  const onClickRequestNewCode = () => {
    setOtpValue('');
    setOtpError(false);
    onRequestNewCode();
  };

  return (
    <StContainer>
      <StTitle>Verifique seu e-mail</StTitle>
      <StContent>
        Enviamos um código de verificação para o email cadastrado.
      </StContent>
      <StContent>Verifique sua caixa de entrada para encontrá-lo.</StContent>
      <StOtpContainer
        onKeyDown={({ key }) => {
          if (key === 'Enter' && isOtpCompleted) {
            onSubmit();
          }
        }}
      >
        <StVerificationCodeTitle>Código de verificação</StVerificationCodeTitle>
        <StVerificationCodeContent>
          Insira o código recebido no campo abaixo:
        </StVerificationCodeContent>
        <StOtpInputContainer>
          <OtpInput
            inputStyle={isOtpError ? otpInputErrorStyle : otpInputStyle}
            value={otpValue}
            onChange={onChangeOtpInput}
            numInputs={otpLength}
            shouldAutoFocus
            isInputNum
          />
          {isOtpError && (
            <StOtpInputErrorMessage>
              O código de verificação foi digitado incorretamente ou expirou.
              Tente novamente ou solicite um novo código.
            </StOtpInputErrorMessage>
          )}
        </StOtpInputContainer>
        <Button type="primary" disabled={!isOtpCompleted} onClick={onSubmit}>
          {isOtpError ? 'Tentar novamente' : 'Continuar'}
        </Button>
        {isOtpError && (
          <StButtonLink type="link" onClick={onClickRequestNewCode}>
            Solicitar novo código
          </StButtonLink>
        )}
      </StOtpContainer>
    </StContainer>
  );
};
