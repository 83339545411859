import React, { useState } from 'react';
import { RegisterStep1 } from './RegisterStep1';
import { RegisterStep2 } from './RegisterStep2';
import { RegisterOtp } from './components/RegisterOtp/RegisterOtp';
import { RegisterSuccess } from './components/RegisterSuccess/RegisterSuccess';
import { FadeWrapper, Wrapper } from './styled';

export const Register = () => {
  const dataTestPrefix = 'register';
  const [step, setStep] = useState(1);
  const [accountData, setAccountData] = useState({});
  const [otpValue, setOtpValue] = useState('');
  const [isOtpError, setOtpError] = useState(false);

  const onFinishStep1 = (values) => {
    setAccountData(values);
    setStep(2);
  };

  const onFinishStep2 = (values) => {
    setAccountData({ ...accountData, ...values });
    setStep(4);
  };

  const onSubmit = () => {
    setStep(3);
  };

  const onRequestNewCode = () => {
    // eslint-disable-next-line no-console
    console.log('Solicitou novo código');
  };

  return (
    <Wrapper>
      <FadeWrapper step={step}>
        {step === 1 && (
          <RegisterStep1
            onFinish={onFinishStep1}
            dataTestPrefix={dataTestPrefix}
          />
        )}
        {step === 2 && (
          <RegisterOtp
            otpValue={otpValue}
            setOtpValue={setOtpValue}
            isOtpError={isOtpError}
            setOtpError={setOtpError}
            onSubmit={onSubmit}
            onRequestNewCode={onRequestNewCode}
          />
        )}
        {step === 3 && (
          <RegisterStep2
            onFinish={onFinishStep2}
            dataTestPrefix={dataTestPrefix}
          />
        )}
        {step === 4 && <RegisterSuccess />}
      </FadeWrapper>
    </Wrapper>
  );
};
