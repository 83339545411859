import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const StOtpContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  margin: 32px;
  padding: 32px;
  background-color: ${colors.gray1};
  border-radius: 4px;
`;

export const StTitle = styled.h1`
  margin: 16px;
  font-size: 24px;
  font-weight: 500;
  color: ${colors.gray8};
`;

export const StVerificationCodeTitle = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: ${colors.gray9};
`;

export const StContent = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.gray8};
`;

export const StVerificationCodeContent = styled.span`
  display: block;
  margin-top: 24px;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.gray9};
`;

export const StOtpInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 24px 0;
`;

export const StOtpInputErrorMessage = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: ${colors.red5};
`;

export const StButtonLink = styled(Button)`
  margin-top: 24px;
`;

export const otpInputStyle = {
  width: '40px',
  height: '40px',
  margin: '8px',
  border: `1px solid ${colors.gray5}`,
  borderRadius: '2px',
};

export const otpInputErrorStyle = {
  ...otpInputStyle,
  borderColor: colors.red6,
};
